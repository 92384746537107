import('./bootstrap');
//import('@fortawesome/fontawesome-free/js/all.js');
// import imagesLoaded from 'imagesloaded';
import('/js/slick-lightbox.js');
import $ from 'jquery';
import slick from 'slick-carousel';
import 'datatables.net';
// require('lightgallery.js');

let $q = document.querySelector.bind(document),
    $$q = document.querySelectorAll.bind(document);
let wishItemId;

function resizeGridItem(item) {
    var grid = document.getElementsByClassName("masonry")[0];
    var rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    var rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    var rowSpan = Math.ceil((item.querySelector('.brick-content').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
    item.style.gridRowEnd = "span " + rowSpan;
}

function resizeAllGridItems() {
    allItems = document.getElementsByClassName("masonry-brick");
    for (var x = 0; x < allItems.length; x++) {
        resizeGridItem(allItems[x]);
    }

    // setTimeout(() => {
    /*lightGallery( document.getElementById('masonry-gallery'),{
        selector: '.brick-content'
    });*/
    //}, 3000)

}

function resizeInstance(instance) {
    var item = instance.elements[0];
    resizeGridItem(item);
}

window.onload = resizeAllGridItems();
window.addEventListener("resize", resizeAllGridItems);

var allItems = document.getElementsByClassName("masonry-brick");
for (var x = 0; x < allItems.length; x++) {
    // imagesLoaded(allItems[x], resizeInstance);
}

document.addEventListener("DOMContentLoaded", function () {

    let $productFilters = $$q('.filter-values-list input[type="checkbox"]');
    let $productCategoryViews = $$q('.product-category-view-type');
    let $viewLimit = $q('#view-limit');

    $('.home-hero').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        //console.log('before', nextSlide);
    });
    $('.home-hero').on('afterChange', function (event, slick, currentSlide) {
        //console.log('after');
    });

    $('.home-hero').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
        appendDots: $q('.hero-dots'),
        pauseOnHover: true,
        fade: true
    });

    $('.testimonials').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        fade: true
    });

    $('.carousel-gallery').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        fade: true
    });

    if( $('.carousel-slides').length ){
        $('.carousel-slides').slick({
            autoplay: window.slickSettings.autoplay,
            autoplaySpeed: window.slickSettings.autoplaySpeed,
            arrows: window.slickSettings.arrows,
            dots: window.slickSettings.dots,
            pauseOnHover: true,
            slidesToShow: 1,
            fade: window.slickSettings.fade,
            prevArrow:"<img class='slick-prev-img' src='../images/prev-arrow.png'>",
            nextArrow:"<img class='slick-next-img' src='../images/next-arrow.png'>"
        });
    }

    $('.product-image-slider').on('click', '.image-modal-link', function (e) {
        e.preventDefault();
        let parent = $(this);
        let href = parent[0].getAttribute('href');
        $('.image-modal-image').attr('src', href);
        $('.image-modal').show();

    });

    $('.image-modal, .image-modal .inner').click(function () {
        $('.image-modal').hide();
    });

    $('.product-image-slider').slick({
        arrows: true,
        prevArrow: '.productPrevArrow',
        nextArrow: '.productNextArrow',
        asNavFor: '.product-image-slider-nav'
    });

    $('.product-image-slider-nav').slick({
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        //centerMode: true,
        //centerPadding: '0px',
        focusOnSelect: true,
        asNavFor: '.product-image-slider'
    });

    if ($productFilters.length) {
        $productFilters.forEach((el) => {
            el.addEventListener('change', (e) => {
                let $filterForm = $q('.filter-form');
                $filterForm.submit();
            });
        });
    }

    if ($productCategoryViews.length) {
        $productCategoryViews.forEach((el) => {
            el.addEventListener('change', (e) => {
                let $filterForm = $q('.filter-form');
                $filterForm.submit();
            });
        });
    }

    if ($viewLimit) {
        $viewLimit.addEventListener('change', (e) => {
            let $filterForm = $q('.filter-form');
            $filterForm.submit();
        });
    }

    $('.custom-file-input').on('change', function () {
        //console.log($(this)[0]);
        let files = $(this)[0].files;
        let name = '';
        for (var i = 0; i < files.length; i++) {
            name += '\"' + files[i].name + '\"' + (
                i != files.length - 1 ? ", " : "");
        }
        $(this).next('.custom-file-label').html(name);
    });

    $('.show-wishlist-modal-btn').click(function (e) {
        e.preventDefault();
        wishItemId = $(this).attr('data-item-id');
        let name = $(this).attr('data-item-name');
        $('#my-list').attr('disabled', false);
        $('.item-name-wish').html(name);
        let list = '';
        $.getJSON('/get-wishlists').done(function (json) {

            $('.wishlist-modal').show();
            if (json.lists.length === 0) {
                list += '<option value="">No existing lists yet.</option>';
                $('#my-list').attr('disabled', 'disabled');
            } else {
                list += '<option value="">Choose ...</option>';
            }
            $.each(json.lists, function (i, v) {
                console.log(v);
                list += '<option value="' + v.id + '">' + v.title + '</option>';
            });
            $('#my-list').html(list);

        }).fail(function (jqxhr, textStatus, error) {
            //var err = textStatus + ", " + error;
            //console.log( "Request Failed: " + err );
            window.location = '/wishlist-login';
        });

    });

    $('.cancel-wishlist-btn').click(function (e) {
        e.preventDefault();
        $('#my-list').val('');
        $('#new-list').val('');
        $('.wishlist-modal-message').html('');
        $('.wishlist-modal').hide();
        $('.item-name-wish').html('');
    });

    $('.add-item-to-wishlist-btn').click(function (e) {
        e.preventDefault();
        let list = $('#my-list').val();
        let title = $('#new-list').val();

        let newList = $('#new-list').val();
        let existingList = $('#my-list').val();
        console.log(existingList);
        if (newList.length === 0 && (existingList === null || existingList.length === 0)) {
            $('.wishlist-modal-message').html('Please choose from an existing list or create a new one.');
            return false;
        }

        let data = {
            item_id: wishItemId,
            title: newList,
            saved_list_id: existingList,
            _token: app.csrfToken
        };

        $.post('/add-item-wishlist', data, function () {

            $('.wishlist-modal-message').html('Item added to wishlist. Thanks!');

            setTimeout(function () {
                $('#my-list').val('');
                $('#new-list').val('');
                $('.wishlist-modal-message').html('');
                $('.wishlist-modal').hide();
                $('.item-name-wish').html('');
            }, 4000);
        });

    });

    /*$('.gallery-item-link').click(function(e){
        e.preventDefault();
        $('.gallery-modal').modal();
    });*/


    $('#gallery-modal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        var image = button.attr('data-image');
        var modal = $(this);
        modal.find('.modal-body img').attr('src', image);
    });

    $('#gallery-modal').on('hidden.bs.modal', function (e) {
        var modal = $(this);
        modal.find('.modal-body img').attr('src', '');
    });

});
